import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Sheet,
  Tooltip,
  Modal,
  ModalDialog,
  Input,
  Select,
  Option,
  Stack,
  FormLabel,
  Grid
} from '@mui/joy';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Config from '../Config';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';

//obrázky
import autoFarmImage from '../icons/auto_farm.webp';
import autoTapImage from '../icons/auto_tap.webp';
import hugeHunterImage from '../icons/huge_hunter.webp';
import luckyImage from '../icons/lucky.webp';
import magicEggsImage from '../icons/magic_eggs.webp';
import moreEggsImage from '../icons/more_eggs.webp';
import petEquipImage from '../icons/pet_equip.webp';
import ultraLuckyImage from '../icons/ultra_lucky.webp';
import vipImage from '../icons/vip.webp';
import enchantImage from '../icons/enchant.webp';
import dayCareImage from "../icons/dayCare.webp";
import superDropImage from "../icons/superDrop.webp"
import doubleStarsImage from "../icons/double_star.webp"


    export default function ApplicationList2() {
        const [applications, setApplications] = useState([]);
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [modalData, setModalData] = useState({ date: '', fee: '', role: 'member' });
        const [rejectData, setRejectData] = useState({})
        const [isRejectModalOpen, setRejectModalOpen] = useState(false)
        const [rejectReason, setRejectReason] = useState(''); // Přidáno pro ukládání důvodu odmítnutí


          const fetchApplications = async () => {
            try {
              const token = localStorage.getItem('token');
              const response = await fetch(`${Config.domain}/api/applications/altpending`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              });
      
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
      
              const data = await response.json();
              setApplications(data);
            } catch (error) {
              console.error('There was a problem with the fetch operation:', error);
            }
          };

          useEffect(() => {
            fetchApplications();
          }, []); 
      
        const handleApprove = (applicationNick, startFee) => {
          setModalData({ 
            nick: applicationNick, 
            date: new Date().toISOString().split('T')[0], // Aktuální datum
            fee: formatNumber(startFee),
            role: 'member'
          });
          setIsModalOpen(true);
        };
      
        const handleReject = (applicationNick) => {
          // Reset důvodu odmítnutí při každém otevření modálního okna
          setRejectData({ nick: applicationNick, reason: '' });
          setRejectModalOpen(true);
        };

        const handleRejectReasonChange = (e) => {
          setRejectData({ ...rejectData, reason: e.target.value });
        };

        const handleSubmit = async () => {
          // Příprava payloadu
          const payload = {
            nick: modalData.nick,
            date: modalData.date,
            fee: modalData.fee,
            role: modalData.role
          };
        
          try {
            const token = localStorage.getItem('token'); // Předpokládám, že token je uložený v localStorage
            const response = await fetch(`${Config.domain}/api/members/approval`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify(payload)
            });
        
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
        
            // Zde můžeš přidat další logiku po úspěšném odeslání dat
            console.log('Approval sent successfully');
          } catch (error) {
            console.error('There was a problem with sending the data:', error);
          }
        
          setIsModalOpen(false);
          setApplications(prevApplications => 
            prevApplications.filter(app => app.nick !== modalData.nick)
          );
        };

        const closeRejectModal = () => {
          setRejectModalOpen(false);
      };

      const handleConfirmReject = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${Config.domain}/api/member/declined`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(rejectData) // Odesíláme objekt s nickem a důvodem
          })
    
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          closeRejectModal()
          fetchApplications()
          console.log('Reject sent successfully');
        } catch (error) {
          console.error('There was a problem with sending the data:', error);
        }
    
        setIsModalOpen(false);
        setApplications(prevApplications => 
          prevApplications.filter(app => app.nick !== modalData.nick)
        );
      };
        
        const handleSelectChange = (event, newValue) => {
          setModalData({...modalData, role: newValue});
        };

        function formatNumber(num) {
          const sign = num < 0 ? '-' : '';
          const absNum = Math.abs(num);
      
          if (absNum < 1000) return sign + absNum; //Stovky
          if (absNum < 1000000) return sign + Math.round(absNum / 1000) + 'k'; // Tisíce
      
          const formatWithOptionalDecimal = (value, unit) => {
              const number = Math.floor(value) === value ? Math.floor(value) : value.toFixed(1);
              return sign + number + unit;
          };
      
          if (absNum < 1000000000) return formatWithOptionalDecimal(absNum / 1000000, 'M'); // Miliony
          if (absNum < 1000000000000) return formatWithOptionalDecimal(absNum / 1000000000, 'B'); // Miliardy
          return formatWithOptionalDecimal(absNum / 1000000000000, 'T'); // Triliony
      }
    

        const translate = (value) => {
          if (value === true) { return "ANO" }
          if (value === false) { return "NE"}
        }

        const renderGamePasses = (gamepasses, enchantSlots, verifiedGamePasses) => {

          const gamePassImages = {
            autofarm: autoFarmImage,
            autotap: autoTapImage,
            hugeHunter: hugeHunterImage,
            lucky: luckyImage,
            magicEggs: magicEggsImage,
            moreEggs: moreEggsImage,
            petEquip: petEquipImage,
            ultraLucky: ultraLuckyImage,
            vip: vipImage,
            daycare: dayCareImage,
            superDrops: superDropImage,
            enchant: enchantImage,
            doubleStars: doubleStarsImage,
          };
          const gamePassElements = Object.entries(gamepasses).map(([key, value]) => {
            const isVerified = verifiedGamePasses[key];
            const imageStyle = isVerified ? {} : { filter: 'grayscale(100%)' };
            if (value === true) {
              return (
                <Tooltip key={key} title={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}>
                  <img src={gamePassImages[key]} alt={key} style={{ width: '25px', height: '25px', ...imageStyle }} />
                </Tooltip>
              );
            }
            return null;
          });
        
        if (enchantSlots > 0) {
          gamePassElements.push(
            <div key="enchant" style={{ position: 'relative' }}>
              <Tooltip title="Enchant slots">
                <img src={gamePassImages['enchant']} alt="enchant" style={{ width: '25px', height: '25px' }} />
              </Tooltip>
              <Typography
                component="span"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '80%',
                  transform: 'translate(-50%, -50%)',
                  color: 'white',
                  textShadow: '0px 0px 8px rgba(0,0,0,0.85)',
                  fontSize: '1rem',
                  fontWeight: "800"
                }}
              >
                {enchantSlots}
              </Typography>
            </div>
          );
        }
      
        return gamePassElements; // Toto je správně umístěný return pro celou funkci
      };
  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        overflow: { xs: 'auto', sm: 'initial' },
      }}
    >
  {isModalOpen && (
    <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} style={{zIndex: "500"}}>
      <ModalDialog style={{width:"450px", borderRadius:"12px", padding:"40px"}}>
      <Stack flexGrow={1} spacing={1}>
        <FormLabel style={{paddingLeft: "2px"}}>Datum přijetí:</FormLabel>
        <Input 
          style={{borderRadius: "8px"}}
          variant="soft" 
          type="date" 
          value={modalData.date} 
          onChange={(e) => setModalData({...modalData, date: e.target.value})}
        />
        </Stack>
        <Stack flexGrow={1} spacing={1}>
        <FormLabel style={{paddingLeft: "2px"}}>Poplatek:</FormLabel>
        <Input 
          style={{borderRadius: "8px"}}
          variant="soft" 
          value={modalData.fee} 
          onChange={(e) => setModalData({...modalData, fee: e.target.value})}
        />
        </Stack>
        <Stack flexGrow={1} spacing={1}>
        <FormLabel style={{paddingLeft: "2px"}}>Role:</FormLabel>
        <Select
          style={{borderRadius: "8px", marginBottom: "15px"}}
          variant="soft" 
          onChange={handleSelectChange}
          defaultValue="member"
        >
          <Option value="member">Member</Option>
          <Option value="officer">Officer</Option>
        </Select>
        </Stack>
        <Button onClick={handleSubmit}>Potvrdit</Button>
      </ModalDialog>
    </Modal>
  )}
   {isRejectModalOpen && (
    <Modal open={isRejectModalOpen} onClose={() => setRejectModalOpen(false)} style={{zIndex: "500"}}>
        <ModalDialog style={{width:"450px", borderRadius:"12px", padding:"40px"}}>
            <Typography variant="h6" textAlign="center" marginBottom="20px">
              Opravdu chcete odmítnout přihlášku hráče {rejectData.nick}?
            </Typography>
            <Input
                fullWidth
                variant="outlined"
                placeholder="Důvod odmítnutí (nepovinné)"
                value={rejectData.reason}
                onChange={handleRejectReasonChange}
                style={{ marginBottom: '20px', borderRadius: "6px" }}
            />
            <Box sx={{ display: 'flex', gap: 1.5, justifyContent: 'flex-end', '& > button': { flex: 'none' } }}>
              <Button
                    color="neutral"
                    variant="outlined"
                    style={{borderRadius: "8px"}}
                    onClick={closeRejectModal}
                >
                    Zrušit
                </Button>
                <Button
                    color="danger"
                    style={{borderRadius: "8px"}}
                    onClick={handleConfirmReject}
                >
                    Odmítnout
                </Button>
            </Box>
        </ModalDialog>
    </Modal>
  )}


  {applications.map((app) => (
      <Card
        orientation="horizontal"
        sx={{
          width: '97%',
          borderRadius: "12px",
          flexWrap: 'wrap',
          overflow: 'auto',
          backgroundColor: "#00000057",
          marginBottom: "20px"
        }}
      >
        <CardContent>
          <Typography fontSize="1.2rem" fontWeight="700" style={{color: "white"}}>
          <a style={{color: "white"}} href={`https://www.roblox.com/users/${app.robloxId}/profile`} target="_blank" rel="noopener noreferrer">{app.nick}</a>
          </Typography>
    
        <Grid container>
          <Grid item xs={6}>
            <Typography level="body-sm" fontSize="1rem" fontWeight="lg" textColor="text.tertiary">
            Discord: {app.discord} <br/> <strong>Titanics:</strong> {app.titanics} | <strong>Huges:</strong> {app.huges} | <strong>DMG pet:</strong> {formatNumber(app.dmgPet)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography level="body-sm" fontSize="1rem" fontWeight="lg" textColor="text.tertiary">
              Poznámka: {app.note}
            </Typography>
          </Grid>
        </Grid>
     
          <Sheet
            sx={{
              bgcolor: '#2a244c',
              borderRadius: '8px',
              p: 1.5,
              pr: 3,
              pl: 3,
              my: 1.5,
              display: 'flex',
              gap: 2,
              pt: 2,
              '& > div': { flex: 1 },
            }}
          >
            <div>
              <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
                Denně spuštěné PS99
              </Typography>
              <Typography fontWeight="lg">{app.playTime} hodin</Typography>
            </div>
            <div>
              <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
                Je členem VLP2
              </Typography>
              <Typography fontWeight="lg">{translate(app.isMember)}</Typography>
            </div>
            <div>
              <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
                Po přijetí zaplatí
              </Typography>
              <Typography fontWeight="lg">{formatNumber(app.startFee)}</Typography>
            </div>
            <div>
              <Typography level="body-xs" fontWeight="lg" fontSize="0.9rem">
                GamePasses
              </Typography>
              <Typography fontWeight="lg">
                <Box sx={{ display: 'flex', gap: '3px' }}>
                {renderGamePasses(app.gamepasses, app.enchantSlots, app.verifiedGamePasses)}

              </Box>
              </Typography>
            </div>
          </Sheet>
          <Box sx={{ display: 'flex', gap: 1.5, '& > button': { flex: 1 } }}>
          <Button
                color="danger"
                style={{borderRadius: "8px"}}
                startDecorator={<CloseIcon />}
                onClick={() => handleReject(app.nick)}
            >
                Zamítnout
            </Button>
            <Button
                color="success"
                style={{borderRadius: "8px"}}
                startDecorator={<CheckIcon />}
                onClick={() => handleApprove(app.nick, app.startFee)}
            >
                Schválit
            </Button>
          </Box>
        </CardContent>
      </Card>
      ))}
    </Box>
  )}