import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Button, LinearProgress } from '@mui/joy';
import MenuIcon from '@mui/icons-material/Menu';
import "./css/dashboard.css"
import MyTable from "./MyTable"
import ClanProfile from "./ClanProfile"
import ApplicationsList from "./ApplicationsList"
import AdminSettings from "./AdminSettings"
import EditTable from "./EditTable"
import PrivateServer from "./PrivateServer"
import Lotto from "./Lotto.js"
import Config from '../Config';
import Shop from "./Shop"
import TerminalRoundedIcon from '@mui/icons-material/TerminalRounded';
import MyConsole from "./MyConsole.js"
import RapCharts from "./RapCharts.js"
import ApplicationsDashboard from "./ApplicationsDashboard.js"
import MyTableDashboard from "./MyTableDashboard.js"

const ResponsiveDiv = styled('div')(({ theme }) => ({
    marginLeft: '11%',
    marginRight: '11%',
    [theme.breakpoints.down('sm')]: {
        marginLeft: '1%',
        marginRight: '1%',
    },
    [theme.breakpoints.down('xs')]: {
        marginLeft: '0%',
        marginRight: '0%',
    }
}));

const Dashboard = () => {
    const [activeContent, setActiveContent] = useState('Clan');
    const [userRole, setUserRole] = useState(null);
    const [userClan, setUserClan] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const fetchRole = async () => {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            if (!token) {
                setUserRole(null); // Uživatel není přihlášen
                setIsLoading(false);
                return;
            }
            try {
                const response = await fetch(`${Config.domain}/api/role`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                if (response.ok) {
                    const data = await response.json();
                    setUserRole(data.role);
                    setUserClan(data.clan)
                } else {
                    // Pokud token není platný, odstraňte ho z Local Storage
                    localStorage.removeItem('token');
                    setUserRole(null);
                }
            } catch (error) {
                console.error('Chyba při načítání role:', error);
                setUserRole(null);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchRole();
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); // Přepne stav otevření/zavření menu
    };

    const getMenuItemsForRole = (role, clan) => {
        const tokenExists = localStorage.getItem('token');
        if (!tokenExists) {
            return ['Clan', 'Členové']; // Uživatel není přihlášen
        }
    
        let menuItems = ['Clan', 'Členové'];
    
        if (role === 'pending' || role === 'declined') {
            return menuItems; // Pouze základní přístup
        }
    
        if (role === 'member' && clan === 'vlp') {
            menuItems = menuItems.concat(['Private server', 'Shop']);
        } else if (role === 'member' && clan === 'vlp2') {
            menuItems = menuItems.concat(['Private server', 'Shop']); // Vyloučení "Lotto" a "Private server"
        }
    
        if (role === 'owner' || role === 'officer' || role === 'dev') {
            return Object.keys(content); // Úplný přístup ke všem položkám
        }
    
        return menuItems;
    };
    
    

    const content = {
        'Clan': <ClanProfile />,
        'Členové': <MyTableDashboard />,
        'Private server': <PrivateServer />,
        'Shop': <Shop />,
        'Nastavení': <AdminSettings />,
        'Přihlášky': <ApplicationsDashboard />,
        'Správa členů': <EditTable />,
        'CMD': <MyConsole />,
    };

    if (isLoading) {
        return <LinearProgress />;
    }

    const accessibleContent = getMenuItemsForRole(userRole, userClan).filter(key => content.hasOwnProperty(key));
    const shouldContentAdapt = ['Členové', 'Přihlášky', 'Správa členů', "Shop", "Lotto", "CMD"].includes(activeContent);

    return (
        <ResponsiveDiv>
            <div className="dashboard">
                <div className="dashboard-background"></div>
                <header className="dashboard-header">
                    <Button onClick={toggleMenu} variant="outlined" className="menu-button" style={{ display: "none" }} >
                        <MenuIcon />
                    </Button>
                    <img src="https://i.ibb.co/WKqnnL8/varmilogopng.png" style={{ height: "70px", position: "absolute", top: "16px" }} alt="Logo" className="dashboard-logo" />
                    <nav className={`dashboard-nav ${isMenuOpen ? "open" : ""}`}>
                        {accessibleContent.map((key) => (
                            <span
                                key={key}
                                className={activeContent === key ? "active" : ""}
                                onClick={() => setActiveContent(key)}
                                style={key === "Clan" ? { marginLeft: "176px" } : {}}
                            >
                                {key}
                            </span>
                        ))}
                    </nav>
                </header>

                <div className="dashboard-content-border">
                    <div className="dashboard-content" style={{ height: shouldContentAdapt ? '82vh' : 'auto' }}>
                        {content[activeContent]}
                    </div>
                </div>
            </div>
        </ResponsiveDiv>
    );
}

export default Dashboard;
