import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Button, LinearProgress, Grid } from '@mui/joy';
import MenuIcon from '@mui/icons-material/Menu';
import "./css/dashboard.css"
import Dashboard from "./Dashboard.js"
import PriceHistoryChart from './PriceHistoryChart';
import { useParams, Link } from 'react-router-dom';
import Config from '../Config';

const ResponsiveDiv = styled('div')(({ theme }) => ({
    marginLeft: '11%',
    marginRight: '11%',
    [theme.breakpoints.down('sm')]: {
        marginLeft: '1%',
        marginRight: '1%',
    },
    [theme.breakpoints.down('xs')]: {
        marginLeft: '0%',
        marginRight: '0%',
    }
}));

const GrafDashboard = () => {
    const [activeContent, setActiveContent] = useState('Clan');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [priceHistories, setPriceHistories] = useState([]);
    const [PriceDayHistories, setPriceDayHistories] = useState([])
    const { itemName } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${Config.domain}/api/getPriceHistory`);
                if (!response.ok) {
                    throw new Error('Nepodařilo se načíst data');
                }
                const data = await response.json();

                // Filtrujeme data pouze pro vybranou položku
                const filteredData = data.filter(history => history.name === itemName);

                // Pro každou položku historie cen odstraníme duplicity
                const formattedData = filteredData.map(history => {
                    const uniquePrices = [];
                    const uniqueDates = [];
                    history.priceHistory.forEach((price, index) => {
                        if (price !== history.priceHistory[index - 1]) {
                            uniquePrices.push(price);
                            uniqueDates.push(history.dateHistory[index]);
                        }
                    });
                    // Seřadíme data od nejstaršího po nejnovější
                    const sortedData = uniqueDates.map((date, index) => ({ date, price: uniquePrices[index] })).sort((a, b) => new Date(a.date) - new Date(b.date));
                    return { ...history, priceHistory: sortedData.map(data => data.price), dateHistory: sortedData.map(data => data.date) };
                });

                setPriceHistories(formattedData);
            } catch (error) {
                console.error('Chyba při načítání dat:', error.message);
            }
        };

        fetchData();
    }, [itemName]);

    useEffect(() => {
        const fetchDayData = async () => {
            try {
                const response = await fetch(`${Config.domain}/api/getAveragePricePerDay`);
                if (!response.ok) {
                    throw new Error('Nepodařilo se načíst data');
                }
                const data = await response.json();
    
                // Filtrujeme data pouze pro vybranou položku
                const filteredData = data.filter(history => history.name === itemName);
    
                setPriceDayHistories(filteredData);
            } catch (error) {
                console.error('Chyba při načítání dat:', error.message);
            }
        };
    
        fetchDayData();
    }, [itemName]);
    

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); // Přepne stav otevření/zavření menu
    };

    const content = {
        'Clan web': <Dashboard />
    };

    const shouldContentAdapt = ['Členové', 'Přihlášky', 'Správa členů', "Shop", "Lotto", "CMD"].includes(activeContent);

    return (
        <ResponsiveDiv>
            <div className="dashboard">
                <div className="dashboard-background"></div>
                <header className="dashboard-header">
                    <Button onClick={toggleMenu} variant="outlined" className="menu-button" style={{ display: "none" }} >
                        <MenuIcon />
                    </Button>
                    <img src="https://img.psyche.lol/images/varmilogosvg.svg" style={{ height: "76px", position: "absolute", top: "16px" }} alt="Logo" className="dashboard-logo" />
                    <nav className={`dashboard-nav ${isMenuOpen ? "open" : ""}`}>
                        <span
                            style={{ marginLeft: "176px", color: "#fff" }}
                        >
                            <Link to="/" style={{color: "#fff"}}>Domů</Link>
                        </span>
                    </nav>
                </header>

                <div className="dashboard-content-border">
                    <div className="dashboard-content" style={{ padding: "20px", height: shouldContentAdapt ? '82vh' : 'auto' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <div>
                                    {priceHistories.map(history => (
                                        <div key={history.name}>
                                              <h1 style={{color: "#fff", paddingBottom: "20px"}}>{history.name}</h1>
                                            <h3 style={{color: "#dbdbdb"}}>Posledních 48h</h3>
                                            <PriceHistoryChart priceHistory={history.priceHistory} dateHistory={history.dateHistory} />
                                        </div>
                                    ))}
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{marginLeft: "50px"}}>
                                    {PriceDayHistories.map(history => (
                                        <div key={history.name}>
                                            <h1 style={{color: "#fff", paddingBottom: "20px"}}>&nbsp;</h1>
                                             <h3 style={{color: "#dbdbdb"}}>Posledních 7 dní</h3>
                                            <PriceHistoryChart priceHistory={history.priceHistory} dateHistory={history.dateHistory} />
                                        </div>
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div>
                                    {PriceDayHistories.map(history => (
                                        <div key={history.name}>
                                            <h1 style={{color: "#fff", paddingBottom: "20px"}}>&nbsp;</h1>
                                             <h3 style={{color: "#dbdbdb"}}>Posledních 30 dní</h3>
                                            <PriceHistoryChart priceHistory={history.priceHistory} dateHistory={history.dateHistory} />
                                        </div>
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </ResponsiveDiv>
    );
}

export default GrafDashboard;