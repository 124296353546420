import React, { useEffect, useState } from 'react';
import { Button, Sheet, Table, Input, Snackbar, Modal, ModalDialog, Grid } from '@mui/joy';
import Config from "../Config"
import AllPets from './AllPets';
import "./css/PetIndex.css"
import CreatableSelect from 'react-select/creatable';
import SearchIcon from '@mui/icons-material/Search';
import DoneIcon from '@mui/icons-material/Done';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

function SendPets({ onClose, open }) {
  const [pets, setPets] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [petName, setPetName] = useState('');
  const [petCount, setPetCount] = useState('');
  const [autocompleteOptions, setAutocompleteOptions] = useState();
  const [petPoints, setPetPoints] = useState(); 
  const [searchTerm, setSearchTerm] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarColor, setSnackbarColor] = useState("");
  const [selectedPet, setSelectedPet] = useState(null);

  useEffect(() => {
    fetchPets();
  }, []);

  const fetchPets = async () => {
    const response = await fetch(`${Config.domain}/api/myreservedpets`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    if (response.ok) {
      const data = await response.json();
      setPets(data.myReservedPets);
    }
  };
  

  const handleAcceptPet = async (petId) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${Config.domain}/api/acceptReservedPet`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ petId })
    });
  
    if (response.ok) {
      // Nastavte inputy na prázdné
      setPetName('');
      setPetCount('');
      setSelectedPet(null);
  
      // Nastavte Snackbar
      setSnackbarMessage('Pet označen jako předaný!');
      setSnackbarColor("success")
      setOpenSnackbar(true);
  
      // Načtěte znovu data
      fetchPets();
    } else {
      // Zpracování chybového stavu (nepovinné)
      const errorData = await response.json();
      setSnackbarMessage(`Chyba: ${errorData.message}`);
      setSnackbarColor("danger")
      setOpenSnackbar(true);
    }
  };

  const handleRejectionPet = async (petId) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${Config.domain}/api/rejectPet`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ petId })
    });
  
    if (response.ok) {
      setSnackbarMessage('Zájemce o peta byl odebrán!');
      setSnackbarColor("success");
      setOpenSnackbar(true);
  
      fetchPets(); // Znovu načtěte data, aby se zobrazily změny
    } else {
      // Zpracování chybového stavu
      const errorData = await response.json();
      setSnackbarMessage(`Chyba: ${errorData.message}`);
      setSnackbarColor("danger");
      setOpenSnackbar(true);
    }
  };
  
  function formatNumber(num, isCWPoints = false) {
    const sign = num < 0 ? '-' : '';
    const absNum = Math.abs(num);
  
    // Speciální formátování pro CW body
    if (isCWPoints) {
        if (absNum < 1000) return sign + absNum; // Pokud je méně než 1000, zobrazit jak je
        if (absNum < 1000000) return sign + (absNum / 1000).toFixed(2) + 'k'; // Pro tisíce s dvěma desetinnými místy
        // Přidejte podmínky pro miliony atd., pokud je to potřeba
    } else {
        // Původní logika pro ostatní hodnoty
        if (absNum < 1000) return sign + absNum; //Stovky
        if (absNum < 1000000) return sign + Math.round(absNum / 1000) + 'k'; // Tisíce
  
        const formatWithOptionalDecimal = (value, unit) => {
            const number = Math.floor(value) === value ? Math.floor(value) : value.toFixed(1);
            return sign + number + unit;
        };
  
        if (absNum < 1000000000) return formatWithOptionalDecimal(absNum / 1000000, 'M'); // Miliony
        if (absNum < 1000000000000) return formatWithOptionalDecimal(absNum / 1000000000, 'B'); // Miliardy
        return formatWithOptionalDecimal(absNum / 1000000000000, 'T'); // Triliony
    }
  
    // Vrátí původní hodnotu pro jiné případy, které nejsou zahrnuty výše
    return sign + absNum;
  }

  return (
    <Modal open={open} onClose={onClose} style={{ zIndex: "1000" }}>
    <ModalDialog sx={{ width: "1100px", borderRadius: "12px", padding: "40px" }}>
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12}>
    <div>
      <Sheet sx={{ overflow: 'auto', maxHeight: "80vh"}}>
    <Table size="sm" stickyHeader hoverRow
      sx={{
        overflow: 'auto',
        '& tbody tr:hover': {
          backgroundColor: 'rgba(255,255,255, 0.1)',
          borderRadius: "18px",
    
        },
        '& tr > *:first-child': {
          position: 'sticky',
          left: 0,
          '@media (max-width:600px)': {
            bgcolor: 'background.surface',
            zIndex: 100
          },
        },
        '@media (max-width:600px)': { 
          width: 'max-content',
        },
        '& thead th:nth-child(6)': { width: '20%' },
        '& thead th:nth-child(1)': { width: '40%' },
      }}>
      <thead>
        <tr>
          <th style={{fontSize: "1rem", paddingLeft: "15px", fontWeight: 600, width: 'var(--Table-firstColumnWidth)'}}>Pet name</th>
          <th style={{textAlign: "left", fontSize: "1rem", fontWeight: 600}}>Zájemce</th>
          <th style={{textAlign: "left", fontSize: "1rem", fontWeight: 600}}>Dis. zájemce</th>
          <th style={{textAlign: "left", fontSize: "1rem", fontWeight: 600}}>Počet ks</th>
          <th style={{textAlign: "left", fontSize: "1rem", fontWeight: 600}}>Celková cena</th>
          <th style={{textAlign: "left", fontSize: "1rem", fontWeight: 600}}>Akce</th>
        </tr>
      </thead>
      <tbody>
      {pets.map((pet) => (
          <tr key={pet.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <td style={{fontSize: "0.85rem", paddingLeft: "15px"}}>{pet.petName}</td>
            <td style={{textAlign: "left", fontSize: "0.85rem"}}>{pet.interestedNick}</td>
            <td style={{textAlign: "left", fontSize: "0.85rem"}}>{pet.interestedDiscord}</td>
            <td style={{textAlign: "left", fontSize: "0.85rem"}}>{pet.pcs}</td>
            <td style={{textAlign: "left", fontSize: "0.85rem"}}>{formatNumber(pet.totalPrice)}</td>
            <td style={{paddingLeft: "15px", fontSize: "0.85rem", marginRight: "200px"}}>
              <Button 
                startDecorator={<DoneIcon style={{ fontSize: 18, paddingLeft: "10px", fontWeight: "800", borderRadius: "12px" }} />}
                onClick={() => handleAcceptPet(pet._id)} 
                size="sm"
                style={{padding: "0px 15px 0px 15px", backgroundImage:"linear-gradient(to right, #6745ed, #DD39FD)"}}
              />
              <Button 
                startDecorator={<CloseRoundedIcon style={{ fontSize: 18, paddingLeft: "10px", fontWeight: "800", borderRadius: "12px" }} />}
                onClick={() => handleRejectionPet(pet._id)} 
                size="sm"
                style={{padding: "0px 15px 0px 15px", marginLeft: "5px", backgroundImage:"linear-gradient(to right, rgba(253,57,57,1), rgba(255,109,109,1)"}}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    </Sheet>
    </div>
    <Snackbar
        autoHideDuration={5000}
        style={{zIndex: 1000, borderRadius: "9px"}}
        color={snackbarColor}
        open={openSnackbar}
        variant="solid"
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      >
      {snackbarMessage}
    </Snackbar>
    </Grid>
                </Grid>
            </ModalDialog>
        </Modal>
  );
}

export default SendPets;
