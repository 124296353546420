import logo from './logo.svg';
import './App.css';
import RegisterForm from "./components/RegisterForm"
import LoginForm from "./components/LoginForm"
import AdminPanel from "./components/AdminPanel"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Root from "./components/Root"
import Dashboard from "./components/Dashboard"
import GrafDashboard from "./components/GrafDashboard"; // Importujeme komponentu pro zobrazení grafů

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} /> {/* Přidáme cestu pro domovskou stránku */}
        <Route path="/rap/:itemName" element={<GrafDashboard />}/> {/* Přidáme cestu pro zobrazení grafů s parametrem itemName */}
        {/* Další cesty můžete přidat podle vašich potřeb */}
      </Routes>
    </Router>
  );
}

export default App;
