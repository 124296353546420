import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';

const PriceHistoryChart = ({ priceHistory, dateHistory }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  // Funkce pro seřazení dat podle data
  const sortDataByDate = (dates, prices) => {
    const sortedData = dates.map((date, index) => ({ date, price: prices[index] })).sort((a, b) => new Date(b.date) - new Date(a.date));
    const sortedDates = sortedData.map(data => data.date);
    const sortedPrices = sortedData.map(data => data.price);
    return { sortedDates, sortedPrices };
  };

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      if (!chartInstance.current) {
        chartInstance.current = new Chart(ctx, {
          type: 'line',
          data: {
            labels: dateHistory,
            datasets: [{
              label: 'Price History',
              data: priceHistory,
              borderColor: '#cc3bfb',
              tension: 0.1,
              spanGaps: true
            }]
          },
          options: {
            plugins: {
              legend: {
                display: false
              }
            },
            scales: {
              x: {
                ticks: {
                  color: '#dbdbdb' // Barva textů osy X
                },
                grid: {
                  color: '#32304750' // Barva mřížky osy X
                }
              },
              y: {
                ticks: {
                  color: '#dbdbdb', // Barva textů osy Y
                  callback: function(value) {
                    if (Math.abs(value) >= 1000000000) {
                      return value / 1000000000 + 'B';
                    } else if (Math.abs(value) >= 1000000) {
                      return value / 1000000 + 'M';
                    } else {
                      return value;
                    }
                  }
                },
                grid: {
                  color: '#32304750' // Barva mřížky osy Y
                }
              }
            },
            tooltips: { 
              callbacks: {
                label: function(tooltipItem, data) {
                  let label = data.datasets[tooltipItem.datasetIndex].label || '';
                  let value = tooltipItem.yLabel;
                  if (Math.abs(value) >= 1000000000) {
                    label += ': ' + value / 1000000000 + 'B';
                  } else if (Math.abs(value) >= 1000000) {
                    label += ': ' + value / 1000000 + 'M';
                  } else {
                    label += ': ' + value;
                  }
                  return label;
                }
              }
            }
          }
        });
      } else {
        // Seřadíme data podle data
        const { sortedDates, sortedPrices } = sortDataByDate(dateHistory, priceHistory);
        chartInstance.current.data.labels = sortedDates;
        chartInstance.current.data.datasets[0].data = sortedPrices;
        chartInstance.current.update();
      }
    }
  }, [priceHistory, dateHistory]);

  return <canvas ref={chartRef} />;
};

export default PriceHistoryChart;
