import React, { useEffect, useState } from 'react';
import { Button, Sheet, Table, Input, Snackbar } from '@mui/joy';
import Config from "../Config"
import AllPets from './AllPets';
import "./css/PetIndex.css"
import CreatableSelect from 'react-select/creatable';
import SearchIcon from '@mui/icons-material/Search';
import SendPets from "./SendPets.js"


function PetIndex() {
  const [pets, setPets] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [petName, setPetName] = useState('');
  const [petCount, setPetCount] = useState('');
  const [autocompleteOptions, setAutocompleteOptions] = useState(); // Předpokládáme, že tyto hodnoty mohou být dynamicky načítány
  const [petPoints, setPetPoints] = useState(); // Filtrovaný seznam mazlíčků
  const [searchTerm, setSearchTerm] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarColor, setSnackbarColor] = useState("");
  const [selectedPet, setSelectedPet] = useState(null);
  const [petPrice, setPetPrice] = useState('');
  const [itemRAP, setItemRAP] = useState("");
  const [itemName, setItemName] = useState("");

  useEffect(() => {
    fetchPets();
    const formattedOptions = AllPets.map(pet => ({ value: pet.name, label: pet.name }));
    setAutocompleteOptions(formattedOptions);
  }, []);

  const fetchPets = async () => {
    const response = await fetch(`${Config.domain}/api/indexpets`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    if (response.ok) {
      const data = await response.json();
      setPets(data.allPets);
      setPetPoints(data.petPoints)
    }
  };
  
  const fetchItems = async () => {
    const response = await fetch(`${Config.domain}/api/indexpets`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    if (response.ok) {
      const data = await response.json();
      setItemRAP(data.price);
      setItemName(data.name)
    }
  };

  const handleAddPet = async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${Config.domain}/api/addpet`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ petName: petName, pcs: petCount, totalPrice: petPrice })
    });
  
    if (response.ok) {
      // Nastavte inputy na prázdné
      setPetName('');
      setPetCount('');
      setSelectedPet(null);
  
      // Nastavte Snackbar
      setSnackbarMessage('Pet úspěšně přidán!');
      setSnackbarColor("success")
      setOpenSnackbar(true);
  
      // Načtěte znovu data
      fetchPets();
    } else {
      // Zpracování chybového stavu (nepovinné)
      const errorData = await response.json();
      setSnackbarMessage(`Chyba: ${errorData.message}`);
      setSnackbarColor("danger")
      setOpenSnackbar(true);
    }
  };

  function zaokrouhliNaCeleCislo(cislo) {
    return Math.round(cislo);
  }
  
  function formatNumber(num, isCWPoints = false) {
    const sign = num < 0 ? '-' : '';
    const absNum = Math.abs(num);
  
    const formatWithOptionalDecimal = (value, divider, unit) => {
      const dividedValue = value / divider;
      let formattedNumber;
      if (Math.floor(dividedValue) !== dividedValue) {
        formattedNumber = dividedValue.toFixed(2);
      } else {
        formattedNumber = dividedValue.toFixed(0);
      }
      return sign + formattedNumber + unit;
    };
  
    if (isCWPoints) {
      if (absNum < 1000) return sign + absNum.toFixed(0);
      if (absNum < 1000000) return sign + (absNum / 1000).toFixed(2) + 'k';
      if (absNum < 1000000000) return formatWithOptionalDecimal(absNum, 1000000, 'M');
      if (absNum < 1000000000000) return formatWithOptionalDecimal(absNum, 1000000000, 'B');
    } else {
      if (absNum < 1000) return sign + absNum.toFixed(0);
      if (absNum < 1000000) return sign + (absNum / 1000).toFixed(0) + 'k';
      if (absNum < 1000000000) return formatWithOptionalDecimal(absNum, 1000000, 'M');
      if (absNum < 1000000000000) return formatWithOptionalDecimal(absNum, 1000000000, 'B');
      return formatWithOptionalDecimal(absNum, 1000000000000, 'T');
    }
  
    return sign + absNum; // Pro jiné případy
  }
  
  
  const handleReservation = async (petId) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${Config.domain}/api/reservepet`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ petId })
    });
  
    if (response.ok) {
      setSnackbarMessage('Pet byl úspěšně rezervován!');
      setSnackbarColor("success");
      setOpenSnackbar(true);
  
      fetchPets(); // Znovu načtěte data, aby se zobrazily změny
    } else {
      // Zpracování chybového stavu
      const errorData = await response.json();
      setSnackbarMessage(`Chyba: ${errorData.message}`);
      setSnackbarColor("danger");
      setOpenSnackbar(true);
    }
  };
  
  const customStyles = {
    input: (provided) => ({
      ...provided,
      color: 'white', // Nastavení barvy textu, který uživatel píše, na bílou
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#171a1c',
      fontSize: "0.95rem",
      borderColor: 'black',
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      outline: state.isFocused ? 'none' : 'none',
      width: "250px",
      color: 'white',
      boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.52)",
      borderRadius: "7px"
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#171a1c',
      color: 'white',
      fontSize: "0.95rem",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'grey' : '#171a1c',
      color: 'white',
      fontSize: "0.95rem",
      ':hover': {
        backgroundColor: 'grey',
        border: "none",
        outline: "none",
        color: "white"
      },
      ':active': {
        backgroundColor: 'grey',
        border: "none",
        outline: "none",
        color: "white"
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
  };
  
  const handleSelectChange = (selectedOption) => {
    setPetName(selectedOption ? selectedOption.value : '');
    setSelectedPet(selectedOption);
  };
  

  const filteredPets = pets.filter(pet =>
    pet.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  const openSendPetsModal = () => {
    setOpenModal(true);
  };
  

  return (
<>
    <div>
    <SendPets open={openModal} onClose={() => setOpenModal(false)} />
     <div style={{position: 'sticky', top: 0, zIndex: 1000, display: "flex", justifyContent: "space-between", alignItems: "center", padding: "15px", paddingLeft: "10px", borderRadius: "6px 6px 0px 0px", backgroundImage:"linear-gradient(to right, #6745ed, #DD39FD)"}}>
        <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                  <Input
                    style={{ borderRadius: "8px", width: "20%", height: "38px" }}
                    variant="soft"
                    className="myNumberInput"
                    type="number"
                    placeholder='Počet ks'
                    value={petCount}
                    onChange={(e) => setPetCount(e.target.value)}
                    sx={{
                      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      }
                    }}
                  />
                  <CreatableSelect value={selectedPet} placeholder="Název předmětu" styles={customStyles} isClearable options={autocompleteOptions} onChange={handleSelectChange}/>
                  <Input
              style={{ borderRadius: "8px", width: "20%", height: "38px" }}
              variant="soft"
              className="myPriceInput"
              placeholder='Ceková cena (50k, 2.3m..)'
              onChange={(e) => setPetPrice(e.target.value)}
              sx={{
                '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                }
              }}
            />
          <Button onClick={handleAddPet} sx={{ height: "38px", borderRadius: "8px", border: "2px solid white", color: "#9A40F4", backgroundColor: "white", fontWeight: 600 }} color="neutral" variant="solid">Přidat</Button>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <Button onClick={openSendPetsModal} sx={{ height: "38px", borderRadius: "8px", border: "2px solid white", color: "white", marginLeft: "10px" }} color="neutral" variant="outlined">K vyřešení</Button>
      <Input
        style={{ borderRadius: "8px" }} // Zde přidejte svůj styl
        placeholder="Vyhledat..."
        value={searchTerm}
        variant="soft"
        endDecorator={<SearchIcon style={{fontSize: "20px"}} />}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
    </div>
      <Sheet sx={{ overflow: 'auto', backgroundColor: "#1D1933"}}>
    <Table size="sm" stickyHeader hoverRow
      sx={{
        backgroundColor: "#1D1933",
        '& tbody tr:hover': {
          backgroundColor: 'rgba(255,255,255, 0.1)',
          borderRadius: "18px",
    
        },
        '& tr > *:first-child': {
          position: 'sticky',
          left: 0,
          '@media (max-width:600px)': {
            bgcolor: 'background.surface',
            zIndex: 100
          },
        },
        '@media (max-width:600px)': { 
          width: 'max-content',
        },
        '& thead th:nth-child(1)': { width: '30%' },
      }}>
      <thead>
        <tr>
          <th style={{fontSize: "1rem", paddingLeft: "15px", fontWeight: 600}}>Název předmětu</th>
          <th style={{textAlign: "left", fontSize: "1rem", fontWeight: 600 }}>Množství</th>
          <th style={{textAlign: "left", fontSize: "1rem", fontWeight: 600}}>Cena za ks (rap)</th>
          <th style={{textAlign: "left", fontSize: "1rem", fontWeight: 600}}>Celkem(rap)</th>
          <th style={{textAlign: "left", fontSize: "1rem", fontWeight: 600}}>Vlastník</th>
          <th style={{textAlign: "left", fontSize: "1rem", fontWeight: 600}}>Dis. Vlastníka</th>
          <th style={{textAlign: "left", fontSize: "1rem", fontWeight: 600}}>Zájemce</th>
          <th style={{textAlign: "right", fontSize: "1rem", fontWeight: 600}}>Akce</th>
        </tr>
      </thead>
      <tbody>
      {filteredPets.map((pet) => (
          <tr key={pet.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <td style={{fontSize: "0.85rem", paddingLeft: "15px"}}>{pet.name}</td>
            <td style={{textAlign: "left", fontSize: "0.85rem"}}>{pet.pcs}</td>
            <td style={{textAlign: "left", fontSize: "0.85rem"}}> 💎 {formatNumber(pet.pcsPrice)} ({formatNumber(pet.rapPrice, true)}) </td>
            <td style={{textAlign: "left", fontSize: "0.85rem", fontWeight: 800, backgroundColor: "#17d0d320", paddingLeft: "10px"}}> 💎 {formatNumber(pet.totalPrice)} ({formatNumber(pet.pcsRapPrice, true)})</td>
            <td style={{textAlign: "left", fontSize: "0.85rem", paddingLeft: "10px"}}>{pet.owner.nick}</td>
            <td style={{textAlign: "left", fontSize: "0.85rem"}}>{pet.owner.discord}</td>
            <td style={{textAlign: "left", fontSize: "0.85rem"}}>{pet.interested ? pet.interested.nick : 'Žádný zájemce'}</td>
            <td style={{paddingLeft: "15px", fontSize: "0.85rem", marginRight: "200px", textAlign: "right"}}><Button disabled={pet.interested != null} onClick={() => handleReservation(pet._id)} size="sm" style={{padding: "0px 15px 0px 15px", backgroundImage:"linear-gradient(to right, #6745ed, #DD39FD)", color: pet.interested != null ? "black" : "white"}}>Rezervovat</Button></td>
          </tr>
        ))}
      </tbody>
    </Table>
    </Sheet>
    </div>
    <Snackbar
        autoHideDuration={5000}
        style={{zIndex: 1000, borderRadius: "9px"}}
        color={snackbarColor}
        open={openSnackbar}
        variant="solid"
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      >
      {snackbarMessage}
    </Snackbar>
    </>
  );
}

export default PetIndex;
