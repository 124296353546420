import React from "react";
import "./css/Tickets.css";
import styled from "styled-components";

const TicketWrapper = styled.div`
  backface-visibility: hidden;
  transform: translateZ(0);
  transform-origin: 50% 50%;
  top: 50%;
  left: 50%;
  margin-top: -65px;
  margin-left: -144px;
  position: relative;
  display: inline-block;
  padding: 15px 25px;
  background-image: radial-gradient(
      ellipse closest-side at 50% 50%,
      hsla(0, 0%, 100%, 0.1),
      transparent 90%
    ),
    radial-gradient(
      circle at 0 100%,
      transparent 14px,
      ${(props) => props.myColor} 15px
    ),
    radial-gradient(
      circle at 100% 100%,
      transparent 14px,
      ${(props) => props.myColor} 15px
    ),
    radial-gradient(
      circle at 100% 0,
      transparent 14px,
      ${(props) => props.myColor} 15px
    ),
    radial-gradient(
      circle at 0 0,
      transparent 14px,
      ${(props) => props.myColor} 15px
    );
  background-position: center center, bottom left, bottom right, top right,
    top left;
  background-size: cover, 51% 51%, 51% 51%, 51% 51%, 51% 51%;
  background-repeat: no-repeat;
  border-width: 0 4px;
  border-color: transparent;
  border-style: solid;
  filter: drop-shadow(hsla(0, 0%, 0%, 0.55) 1px 1px 2px);
`;

// Styly pro div uvnitř TicketWrapper
const TicketContent = styled.div`
  width: 230px;
  height: 100px;
  box-sizing: border-box;
  position: relative;
  border-color: ${(props) => props.myBorderColor};
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  text-align: center;
  font: 1.8em/1 Impact;
  text-transform: uppercase;
  padding: 20px;
  font-stretch: extra-expanded;
  color: ${(props) => props.textColor};

  &:before,
  &:after {
    content: attr(data-number);
    font: 0.625em/1 "Courier New";
    position: absolute;
    width: 96px;
    line-height: 28px;
    transform: rotate(90deg) translateZ(0);
    transform-origin: 0% 0%;
    text-align: center;
    display: block;
    top: 0;
    border-color: ${(props) => props.myBorderColor};
    border-style: solid;
    border-width: 2px 0 0;
  }

  &:before {
    left: 28px;
  }

  &:after {
    transform-origin: 100% 0%;
    right: 28px;
    transform: rotate(-90deg) translateZ(0);
  }
`;

// Komponenta pro řádek textu uvnitř TicketContent
const TicketRow = styled.span`
  font-size: 0.8rem;
  font-weight: 500;
  font-family: Roboto;
`;

// Komponenta Ticket s podporou pro dynamické upravování
const Ticket = ({ number, lowNum, nick, color }) => {
  let myColor = "";
  let myBorderColor = "";
  let textColor = "";
  let myBg = "";
  if (color === "purple") { //Owned
    myBorderColor = "#f549ff";
    myColor = "#6745ed";
    textColor = "white";
  } else if (color === "green") { //free
    myBorderColor = "#3b326d";
    myColor = "#131122";
    textColor = "white";
  } else if (color === "red") { //reserved
    myBorderColor = "#131122";
    myColor = "#2A244C";
    textColor = "grey";
  }
  // Inline styly pro .ticket a .ticket > div, které dynamicky upravují barvus

  return (
    <TicketWrapper myColor={myColor}>
      <TicketContent
        myBorderColor={myBorderColor}
        textColor={textColor}
        data-number={number}
      >
        <span style={{fontSize: "1.4rem", lineHeight: "0.2"}}>TICKET #{lowNum}</span>
        <br />
        <TicketRow>{nick}</TicketRow>
      </TicketContent>
    </TicketWrapper>
  );
};

export default Ticket;
