import * as React from 'react';
import { Box, Typography, Button } from '@mui/joy';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import Config from '../Config';

const PrivateServer = () => {

  const ConnectPrivate = () => {
    const url = `${Config.private}`; 
    window.open(url, '_blank');
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'start', gap: 2, padding: "15px" }}>
      <img style={{ borderRadius: "8px", maxWidth: "100%", height: 'auto', flex: 1 }} src="https://tr.rbxcdn.com/b4f3eb329b52b3eb90185b651867471b/768/432/Image/Png" alt="Popis obrázku" />
      <Box sx={{ flex: 2 }}>
        <Typography level="h3" sx={{ mb: 2 }}>Pravidla private serveru:</Typography>
        <p style={{color: "white"}}>➤ Server je vyhrazen pro všechny členy VLP, šíření odkazu mimo clan není povoleno!</p>
        <p style={{color: "white"}}>➤ V poslední lokaci je povoleno používat pouze Magnet Flag, žádná jinou vlajku v poslední lokaci nechceme vidět. Všechny ostatní předměty si házejte dle libosti.</p>
        <p style={{color: "white"}}>➤ Buďte ohleduplní k ostatním a nelogujte zbytečně na server 5 altů, ať je prostor i pro ostatní hráče.</p>
        <p style={{color: "white"}}>➤ Když je Varmi na serveru neskákejte mu po hlavě a nechovejte se jako 13 leté holky, když vidí svého idola..</p>
        <p style={{color: "white"}}>➤ Pokud nejste AFK a stojíte v poslední lokaci, pokládejte prosím Magnet Flagy, získáte za to malé plus!</p>
        <Box sx={{ display: 'flex', gap: 1.5, '& > button': { flex: 1 } }}>
          <Button
            fullWidth
            style={{borderRadius: "8px", fontWeight: 600}}
            color="success"
            onClick={ConnectPrivate}
            startDecorator={<PlayArrowRoundedIcon style={{fontSize: "30px"}}/>}
          >
            Připojit na server
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivateServer;
