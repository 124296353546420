import React, { useState } from "react";
import {
   Button,
   Grid,
   Card,
   extendTheme,
   CssVarsProvider,
   Input,
   Alert,
   Checkbox,
   Typography,
   Sheet,
   FormLabel,
   Modal,
   ModalDialog,
   Radio,
   RadioGroup,
   List,
   ListItem,
   ListItemDecorator,
   Textarea
} from "@mui/joy";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { FormControlLabel, FormGroup } from "@mui/material";
import Config from "../Config";

function RegisterForm({ open, onClose, onRegistrationSuccess }) {
   const [formData, setFormData] = useState({
      nick: "",
      discord: "",
      password: "",
      confirmPassword: "",
      isMember: false,
      enchantSlots: 0,
      playTime: 0,
      startFee: 0,
      note: "",
      dmgPet: 0,
      huges: 0,
      titanics: 0,
      clanType: "vlp",
      gamepasses: {
         lucky: false,
         ultraLucky: false,
         hugeHunter: false,
         magicEggs: false,
         vip: false,
         autofarm: false,
         autotap: false,
         petEquip: false,
         moreEggs: false,
         superDrops: false,
         daycare: false,
         doubleStars: false
      },
   });
   const [errors, setErrors] = useState({});
   const [serverError, setServerError] = useState("");
   const [validationError, setValidationError] = useState("");

   // Funkce pro změnu hodnot ve formuláři
   const handleChange = (event) => {
      const { name, value, checked, type } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      }));
    };

   const validateForm = () => {
      let tempErrors = {};
      let isValid = true;
      if (!formData.nick) {
         tempErrors.nick = "Nick je povinný!";
         isValid = false;
      }
      if (!formData.discord) {
         tempErrors.discord = "Discord je povinný!";
         isValid = false;
      }
      if (!formData.password) {
         tempErrors.password = "Heslo je povinné!";
         isValid = false;
      } else if (formData.password.length < 6) {
         tempErrors.password = "Heslo musí mít alespoň 6 znaků!";
         isValid = false;
      }

      if (formData.password !== formData.confirmPassword) {
         tempErrors.confirmPassword = "Hesla se neshodují!";
         isValid = false;
      }

      if (
         formData.enchantSlots < 0 ||
         formData.enchantSlots > 3 ||
         isNaN(formData.enchantSlots)
      ) {
         tempErrors.enchantSlots = "Počet slotů musí být číslo od 0 do 3!";
         isValid = false;
      }

      if (
         formData.playTime < 1 ||
         formData.playTime > 24 ||
         isNaN(formData.playTime)
      ) {
         tempErrors.playTime = "Hodnota musí být číslo od 1 do 24!";
         isValid = false;
      }

      if (
         !/^(0|(\d+(\.\d+)?[a-zA-Z]{1}))$/.test(formData.startFee.toString()) ||
         formData.startFee.toString().replace(/[^a-zA-Z]/g, "").length > 1
      ) {
         tempErrors.startFee =
            'Zadaná hodnota musí být ve formátu např. "100k", "1M", 1.2M...';
         isValid = false;
      }

      if (
         !/^(0|(\d+(\.\d+)?[a-zA-Z]{1}))$/.test(formData.startFee.toString()) ||
         formData.dmgPet.toString().replace(/[^a-zA-Z]/g, "").length > 1
      ) {
         tempErrors.dmgPet =
            'Zadaná hodnota musí být ve formátu např. "100m", "300m", 1.2M...';
         isValid = false;
      }

      setErrors(tempErrors);

      if (!isValid) {
         const firstError = Object.values(tempErrors).find(Boolean);
         setValidationError(firstError);
      } else {
         setValidationError("");
      }

      return isValid;
   };

   const sendToServer = async (data) => {
      try {
         const response = await fetch(`${Config.domain}/api/register`, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               nick: formData.nick,
               discord: formData.discord,
               password: formData.password,
               enchantSlots: formData.enchantSlots,
               playTime: formData.playTime,
               startFee: formData.startFee,
               gamepasses: formData.gamepasses,
               isMember: formData.isMember,
               note: formData.note,
               dmgPet: formData.dmgPet,
               huges: formData.huges,
               titanics: formData.titanics,
               clanType: formData.clanType
            }),
         });
         const result = await response.json();
         if (!response.ok) {
            throw new Error(
               result.message || `HTTP error! status: ${response.status}`
            );
         }
         onRegistrationSuccess(true);
         onClose();
         console.log(result);
      } catch (e) {
         console.error("Chyba při odesílání dat: ", e.message);
         setServerError(e.message);
      }
   };

   const handleSubmit = (event) => {
      event.preventDefault();
      if (validateForm()) {
         sendToServer({
            nick: formData.nick,
            discord: formData.discord,
            password: formData.password,
            enchantSlots: formData.enchantSlots,
            playTime: formData.playTime,
            startFee: formData.startFee,
            gamepasses: formData.gamepasses,
            isMember: formData.isMember,
            note: formData.note,
            dmgPet: formData.dmgPet,
            huges: formData.huges,
            titanics: formData.titanics
         });
      }
   };

   const handleCheckboxChange = (event) => {
      if (event.target.name === "isMember") {
         setFormData({ ...formData, isMember: event.target.checked });
      } else {
         setFormData({
            ...formData,
            gamepasses: {
               ...formData.gamepasses,
               [event.target.name]: event.target.checked,
            },
         });
      }
   };

   const formatLabel = (label) => {
      return label
         .replace(/([A-Z])/g, " $1")
         .toLowerCase()
         .split(" ")
         .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
         .join(" ");
   };

   const radioOptions = [
      { value: "vlp", label: "Main Clan (VLP)" }, 
      { value: "vlp2", label: "Alt Clan (VLP2)" }
    ];
  
    const radioGroup = (
      <RadioGroup
  row
  aria-label="clan type"
  name="clanType"
  defaultValue="vlp"
  value={formData.clanType}
  onChange={handleChange}
  sx={{
    width: '100%', // Zabere celou dostupnou šířku
    marginBottom: 0,
  }}
>
  <List
    sx={{
      display: 'flex',
      flexDirection: 'row', // Položky vedle sebe
      width: '100%', // Zabere celou dostupnou šířku
      gap: 2
    }}
  >
    {radioOptions.map((option) => (
      <ListItem
        key={option.value}
        variant="outlined"
        sx={{
          flexGrow: 1, // Rozloží položky rovnoměrně
          boxShadow: 'sm',
          justifyContent: 'center', // Centruje obsah ListItem
          alignItems: 'center',
          display: 'flex',
          backgroundColor: "#171a1c",
          '--ListItem-radius': '8px', // Přizpůsobení borderRadius pro ListItem
        }}
      >
        <Radio
          overlay
          value={option.value}
          label={option.label}
          sx={{
            width: '100%', // Zabere celou dostupnou šířku v ListItem
            justifyContent: 'center', // Centruje Radio button a label
          }}
          slotProps={{
            action: ({ checked }) => ({
              sx: (theme) => ({
                ...(checked && {
                  inset: -1,
                  border: '2px solid',
                  borderColor: '#d939fc',
                  borderRadius: '8px',
                }),
              }),
            }),
          }}
        />
      </ListItem>
    ))}
  </List>
</RadioGroup>

    );
  

   const theme = extendTheme({ cssVarPrefix: "demo" });
   return (
      <Modal open={open} onClose={onClose} style={{ zIndex: "500",  height: "100vh", overflow: "auto" }}>
         <ModalDialog
            sx={{
               width: "700px",
               height: "max-content",
               borderRadius: "12px",
               padding: "40px",
               overflow: "auto", 
               "@media (max-width:600px)": {
                  height: "max-content",
                  overflow: "auto",
               },
            }}
         >
            <Grid
               container
               justifyContent="center"
               alignItems="center"
               sx={{
                  minHeight: "85vh",
                  "@media (max-width:600px)": {
                     minHeight: "100vh",
                  },
               }}
            >
               <Grid item xs={12} sm={12} md={12}>
                  {(validationError || serverError) && (
                     <Alert
                        color="danger"
                        variant="solid"
                        severity="error"
                        style={{
                           marginBottom: "15px",
                           border: "1px solid red",
                           borderRadius: "8px",
                        }}
                     >
                        <strong>{validationError || serverError}</strong>
                     </Alert>
                  )}
                  <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <FormLabel
                        style={{
                           paddingBottom: "5px",
                           fontSize: "0.85rem",
                        }}
                     >
                        Do kterého clanu se chceš přidat?
                     </FormLabel>
                  {radioGroup}
                  </Grid>
                        <Grid item xs={6}>
                     <FormLabel
                        style={{
                           paddingBottom: "5px",
                           fontSize: "0.85rem",
                        }}
                     >
                        Roblox nick:
                     </FormLabel>
                     <Input
                        placeholder="Roblox nick"
                        variant="soft"
                        name="nick"
                        value={formData.nick}
                        onChange={handleChange}
                        error={!!errors.nick}
                        helperText={errors.nick}
                        style={{
                           marginBottom: "15px",
                           borderRadius: "8px",
                        }}
                     />
                     </Grid>
                     <Grid item xs={6}>
                     <FormLabel
                        style={{
                           paddingBottom: "5px",
                           fontSize: "0.85rem",
                        }}
                     >
                        Discord:
                     </FormLabel>
                     <Input
                        placeholder="Discord"
                        variant="soft"
                        name="discord"
                        value={formData.discord}
                        onChange={handleChange}
                        error={!!errors.discord}
                        helperText={errors.discord}
                        style={{
                           marginBottom: "15px",
                           borderRadius: "8px",
                        }}
                     />
                     </Grid>
                     </Grid>
                     <FormLabel
                        style={{
                           paddingBottom: "5px",
                           fontSize: "0.85rem",
                        }}
                     >
                        Ihned po přijetí vložím do clanu (např. 100k, 2m,
                        1.2m..):
                     </FormLabel>
                     <Input
                        placeholder="např. 100k, 2m, 4.5m"
                        variant="soft"
                        name="startFee"
                        value={formData.startFee}
                        onChange={handleChange}
                        error={!!errors.startFee}
                        helperText={errors.startFee}
                        style={{
                           marginBottom: "15px",
                           borderRadius: "8px",
                        }}
                     />
                     <FormLabel
                        style={{
                           paddingBottom: "5px",
                           fontSize: "0.85rem",
                        }}
                     >
                        Kolik hodin denně máte spuštěný roblox (průměrně):
                     </FormLabel>
                     <Input
                        placeholder="Např. 11, 15, 20"
                        variant="soft"
                        name="playTime"
                        type="number"
                        value={formData.playTime}
                        onChange={handleChange}
                        error={!!errors.playTime}
                        helperText={errors.playTime}
                        style={{
                           marginBottom: "15px",
                           borderRadius: "8px",
                        }}
                     />
                     <Grid container spacing={2}>
                        <Grid item xs={6}>
                           <FormLabel
                              style={{
                                 paddingBottom: "5px",
                                 fontSize: "0.85rem",
                              }}
                           >
                              Vytvořit heslo:
                           </FormLabel>
                           <Input
                              type="password"
                              placeholder="Vytvořit heslo"
                              variant="soft"
                              name="password"
                              value={formData.password}
                              onChange={handleChange}
                              error={!!errors.password}
                              helperText={errors.password}
                              style={{
                                 marginBottom: "15px",
                                 borderRadius: "8px",
                              }}
                           />
                        </Grid>
                        <Grid item xs={6}>
                           <FormLabel
                              style={{
                                 paddingBottom: "5px",
                                 fontSize: "0.85rem",
                              }}
                           >
                              Znovu heslo:
                           </FormLabel>
                           <Input
                              type="password"
                              placeholder="Potvrdit heslo"
                              variant="soft"
                              name="confirmPassword"
                              value={formData.confirmPassword}
                              onChange={handleChange}
                              error={!!errors.confirmPassword}
                              helperText={errors.confirmPassword}
                              style={{
                                 marginBottom: "15px",
                                 borderRadius: "8px",
                              }}
                           />
                        </Grid>
                     </Grid>
                     <FormLabel style={{ paddingBottom: "5px", fontSize: "0.85rem" }}>Počet CW bodů, něco o vás a další info:</FormLabel>
                        <Textarea
                            placeholder="Kolik máš CW bodů, kolik jich za den uděláš, něco o vás a další info..."
                            variant="soft"
                            name="note"
                            value={formData.note}
                            onChange={handleChange}
                            fullWidth
                            style={{ marginBottom: "15px", borderRadius: "8px" }}
                        />
                        <Typography
                           component="h6"
                           style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                              margintTop: "10px"
                           }}
                        >
                           Pet equip:
                        </Typography>
                        <Grid container spacing={1}>
                        <Grid item xs={4}>
                           <FormLabel
                              style={{
                                 paddingBottom: "5px",
                                 fontSize: "0.85rem",
                              }}
                           >
                              DMG best peta:
                           </FormLabel>
                           <Input
                              type="string"
                              placeholder="např. 200m"
                              variant="soft"
                              name="dmgPet"
                              value={formData.dmgPet}
                              onChange={handleChange}
                              error={!!errors.dmgPet}
                              helperText={errors.dmgPet}
                              style={{
                                 marginBottom: "15px",
                                 borderRadius: "8px",
                              }}
                           />
                        </Grid>
                        <Grid item xs={4}>
                           <FormLabel
                              style={{
                                 paddingBottom: "5px",
                                 fontSize: "0.85rem",
                              }}
                           >
                              Počet huges:
                           </FormLabel>
                           <Input
                              type="number"
                              placeholder="Celkový počet huges"
                              variant="soft"
                              name="huges"
                              value={formData.huges}
                              onChange={handleChange}
                              error={!!errors.huges}
                              helperText={errors.huges}
                              style={{
                                 marginBottom: "15px",
                                 borderRadius: "8px",
                              }}
                           />
                        </Grid>
                        <Grid item xs={4}>
                           <FormLabel
                              style={{
                                 paddingBottom: "5px",
                                 fontSize: "0.85rem",
                              }}
                           >
                              Počet titanků:
                           </FormLabel>
                           <Input
                              type="number"
                              placeholder="Celkový počet titaniků"
                              variant="soft"
                              name="titanics"
                              value={formData.titanics}
                              onChange={handleChange}
                              error={!!errors.titanics}
                              helperText={errors.titanics}
                              style={{
                                 marginBottom: "15px",
                                 borderRadius: "8px",
                              }}
                           />
                        </Grid>
                     </Grid>
                     <div style={{ marginBottom: "15px" }}>
                        <Typography
                           component="h6"
                           style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                              marginTop: "15px"
                           }}
                        >
                           Moje gamepassy:
                        </Typography>
                        <FormLabel
                           style={{
                              paddingBottom: "5px",
                              fontSize: "0.85rem",
                           }}
                        >
                           Počet bonus enchant slotů (max. 3):
                        </FormLabel>
                        <Input
                           type="number"
                           placeholder="Počet bonus enchant slotů (max. 3)"
                           variant="soft"
                           name="enchantSlots"
                           value={formData.enchantSlots}
                           onChange={handleChange}
                           error={!!errors.enchantSlots}
                           helperText={errors.enchantSlots}
                           style={{
                              marginBottom: "15px",
                              borderRadius: "8px",
                           }}
                        />
                        <Sheet
                           sx={{
                              bgcolor: "background.level1",
                              borderRadius: "8px",
                              p: 1.5,
                              my: 1.5,
                              display: "flex",
                              gap: 2,
                              "& > div": { flex: 1 },
                           }}
                        >
                           <FormGroup>
                              <Grid container spacing={0}>
                                 {Object.keys(formData.gamepasses).map(
                                    (key, index) => (
                                       <Grid item xs={4} key={key}>
                                          <FormControlLabel
                                             control={
                                                <Checkbox
                                                   name={key}
                                                   checked={
                                                      formData.gamepasses[key]
                                                   }
                                                   onChange={
                                                      handleCheckboxChange
                                                   }
                                                   style={{
                                                      marginRight: "8px",
                                                      marginLeft: "12px",
                                                   }}
                                                />
                                             }
                                             label={formatLabel(key)}
                                          />
                                       </Grid>
                                    )
                                 )}
                              </Grid>
                           </FormGroup>
                        </Sheet>
                        <FormControlLabel
                           control={
                              <Checkbox
                                 name="isMember"
                                 checked={formData.isMember}
                                 onChange={handleCheckboxChange}
                                 style={{
                                    marginRight: "8px",
                                    marginLeft: "12px",
                                 }}
                              />
                           }
                           label="Jsem členem VLP2 clanu"
                        />
                     </div>
                     <Button
                        endDecorator={<KeyboardArrowRight />}
                        type="submit"
                        style={{
                           borderRadius: "8px",
                           marginTop: "15px",
                           marginBottom: "50px",
                           backgroundImage:
                              "linear-gradient(to right, #6745ed, #DD39FD)",
                        }}
                        fullWidth
                     >
                        Registrovat
                     </Button>
                  </form>
               </Grid>
            </Grid>
         </ModalDialog>
      </Modal>
   );
}

export default RegisterForm;
