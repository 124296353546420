import React, { useEffect, useState } from "react";
import Ticket from "./Tickets";
import {
   Modal,
   ModalDialog,
   Typography,
   Box,
   Button,
   Snackbar,
} from "@mui/joy";
import CloseIcon from "@mui/icons-material/Close";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import Grid from "@mui/joy/Grid";
import Config from "../Config";
import styled from "styled-components";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import { formatDistanceToNowStrict } from "date-fns";
import Countdown from "./Count";
import LocalActivityRoundedIcon from "@mui/icons-material/LocalActivityRounded";
import RewardTable from "./RewardTable";
import MilitaryTechRoundedIcon from "@mui/icons-material/MilitaryTechRounded";

const TicketsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); // Uprav podle potřeby
  gap: 20px; // Mezera mezi ticketů
  padding: 20px;
`;

const Lotto = () => {
   const [tickets, setTickets] = useState([]);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [selectedTicketId, setSelectedTicketId] = useState(null);
   const [snackbarMessage, setSnackbarMessage] = useState("");
   const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
   const [snackbarColor, setSnackbarColor] = useState("");
   const [lotteryInfo, setLotteryInfo] = useState({
      endLotto: "",
      freeTickets: 0,
   });
   const [countdown, setCountdown] = useState("");
   const [open, setOpen] = useState(false); // Stav pro ovládání otevření/zavření modalu
   const [rewardsData, setRewardsData] = useState([]); // Stav pro
   const [selectedTicket, setSelectedTicket] = useState(null);
   const [targetEndDate, setTargetEndDate] = useState();


   useEffect(() => {
      fetchLotteryInfo();
      fetchTickets();
      fetchSettings();
   }, []);

   useEffect(() => {
      // Dynamické načtení dat z lokálního JSON souboru
      import("./rewards.json")
         .then((data) => {
            setRewardsData(data.default);
         })
         .catch((error) => {
            console.error("Nelze načíst data z rewards.json", error);
         });
    }, []);
    

   const fetchTickets = async () => {
      const token = localStorage.getItem("token");
      try {
         const response = await fetch(`${Config.domain}/api/tickets`, {
            method: "GET",
            headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${token}`,
            },
         });

         if (!response.ok) {
            throw new Error("Network response was not ok");
         }

         const data = await response.json();
         setTickets(data); // Předpokládá, že API přímo vrací pole ticketů
         console.log("ticketsData: ", data);
      } catch (error) {
         console.error("There was a problem with fetching tickets:", error);
      }
   };

   const fetchLotteryInfo = async () => {
      const token = localStorage.getItem("token");
      try {
         const response = await fetch(`${Config.domain}/api/lottery/info`, {
            headers: {
               Authorization: `Bearer ${token}`,
               "Content-Type": "application/json",
            },
         });

         if (!response.ok) {
            throw new Error("Žádná odpověď ze serveru...");
         }

         const data = await response.json();
         setLotteryInfo({
            endLotto: data.endLotto,
            freeTickets: data.freeTickets,
         });
      } catch (error) {
         console.error("Chyba:", error);
      }
   };

   const fetchSettings = async () => {
      const token = localStorage.getItem('token'); // Předpokládá se, že token je uložen v localStorage
      try {
        const response = await fetch('https://clan.varmi.cz/api/clan/info', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch clan settings');
        }
  
        const data = await response.json();
        const endLottoDate = convertDateTimeToIso(data.settings.endLotto);
        console.log("datum: ", endLottoDate)
        setTargetEndDate(endLottoDate);
        console.log(targetEndDate)
      } catch (error) {
        console.error('Error fetching clan settings:', error);
        // Zde by měla být nějaká logika pro ošetření chyb, např. zobrazení chybového hlášení
      }
    };

    const convertDateTimeToIso = (dateTimeStr) => {
      // Upravený regex, který akceptuje jednu nebo žádnou mezeru kolem teček
      const dateTimePattern = /^(\d{2})\.?\s?(\d{2})\.?\s?(\d{4})\s(\d{2}):(\d{2})$/;
      const match = dateTimePattern.exec(dateTimeStr);
      if (match) {
        const [, day, month, year, hour, minute] = match;
        return `${year}-${month}-${day}T${hour}:${minute}:00`;
      }
      return null;
    };
    

   const handleOpenModal = (ticket) => {
      setSelectedTicket(ticket); // Uložení celého ticketu do stavu
      setIsModalOpen(true);
   };

   const handleCloseSnackbar = () => {
      setIsSnackbarOpen(false);
   };

   const handleBuyTicket = async () => {
      const token = localStorage.getItem("token");
      try {
         const response = await fetch(`${Config.domain}/api/tickets/buy`, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ ticketId: selectedTicket._id }),
         });

         if (!response.ok) {
            throw new Error("Nepodařilo se zakoupit ticket.");
         }

         await fetchTickets();
         setSnackbarColor("success");
         setSnackbarMessage("Ticket byl úspěšně zakoupen.");
         setIsSnackbarOpen(true);
      } catch (error) {
         console.error("There was a problem with buying the ticket:", error);
         setSnackbarColor("danger");
         setSnackbarMessage("Chyba: ", error);
         setIsSnackbarOpen(true);
      }
   };

   const targetDate = new Date("2024-03-15T18:00:00");

   return (
      <div>
         <div
            style={{
               position: "sticky",
               top: 0,
               zIndex: 1000,
               display: "flex",
               justifyContent: "space-between",
               alignItems: "center",
               padding: "15px",
               paddingLeft: "10px",
               borderRadius: "6px 6px 0px 0px",
               backgroundImage: "linear-gradient(to right, #6745ed, #DD39FD)",
            }}
         >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
               <Typography
                  component="h2"
                  style={{ fontWeight: "bold", color: "white" }}
               >
                  <Countdown
                     style={{ fontSize: "3rem" }}
                     targetDate={targetDate}
                  />
               </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
               {/* Volné tickety */}
               <div>
                  <Button
                     onClick={() => setOpen(true)}
                     sx={{
                        height: "38px",
                        borderRadius: "8px",
                        border: "2px solid white",
                        color: "white",
                        marginLeft: "10px",
                     }}
                     color="neutral"
                     variant="outlined"
                     startDecorator={<MilitaryTechRoundedIcon style={{size: 32, color: "white"}} />}> 
                    Odměny
                  </Button>
                  <RewardTable
                     open={open}
                     onClose={() => setOpen(false)}
                     rewards={rewardsData}
                  />
               </div>
               <div
                  style={{
                     backgroundColor: "#ffffff99",
                     padding: "8px 18px 8px 18px",
                     color: "#D839FC",
                     borderRadius: "12px",
                     fontWeight: "800",
                     fontFamily: "Oxanium",
                     fontSize: "1.5rem",
                  }}
               >
                  Tickets: {lotteryInfo.freeTickets}
               </div>
            </div>
         </div>

         <Grid
            container
            spacing={2}
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
         >
            <div>
               {/* Zde přijde zbytek JSX včetně mapování ticketů */}
               {isModalOpen && (
                  <Modal
                     open={isModalOpen}
                     onClose={() => setIsModalOpen(false)}
                     style={{ zIndex: 500 }}
                  >
                     <ModalDialog
                        style={{
                           width: "450px",
                           borderRadius: "12px",
                           padding: "40px",
                        }}
                     >
                        <Typography
                           component="h4"
                           style={{ fontWeight: "bold", marginBottom: "15px" }}
                        >
                           Opravdu chcete koupit ticket č.{" "}
                           {selectedTicket.number}?
                        </Typography>
                        <Box
                           sx={{
                              display: "flex",
                              gap: 1.5,
                              "& > button": { flex: 1 },
                           }}
                        >
                           <Button
                              color="neutral"
                              variant="outlined"
                              style={{ borderRadius: "8px" }}
                              startDecorator={
                                 <CloseIcon style={{ fontSize: 20 }} />
                              }
                              onClick={() => setIsModalOpen(false)}
                           >
                              NE
                           </Button>
                           <Button
                              style={{
                                 borderRadius: "8px",
                                 backgroundImage:
                                    "linear-gradient(to right, #6745ed, #DD39FD)",
                              }}
                              startDecorator={
                                 <ShoppingCartRoundedIcon
                                    style={{ fontSize: 20 }}
                                 />
                              }
                              onClick={() => {
                                 handleBuyTicket();
                                 setIsModalOpen(false);
                              }}
                           >
                              <strong>KOUPIT</strong>
                           </Button>
                        </Box>
                     </ModalDialog>
                  </Modal>
               )}
               <Snackbar
                  autoHideDuration={5000}
                  style={{ zIndex: 1000, borderRadius: "9px" }}
                  color={snackbarColor}
                  open={isSnackbarOpen}
                  variant="solid"
                  onClose={() => setIsSnackbarOpen(false)}
                  message={snackbarMessage}
               >
                  {snackbarMessage}
               </Snackbar>
            </div>
            {tickets.map((ticket, index) => (
               <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  lg={2.4}
                  key={index}
                  style={{ paddingTop: "2%" }}
               >
                  {/* Přidání onClick události pro otevření modálního okna */}
                  <div
                     style={{ marginTop: "60px" }}
                     onClick={() => handleOpenModal(ticket)}
                  >
                     <Ticket
                        number={ticket.number}
                        lowNum={ticket.lowNum}
                        nick={ticket.nick}
                        color={ticket.color}
                     />
                  </div>
               </Grid>
            ))}
         </Grid>
      </div>
   );
};

export default Lotto;
