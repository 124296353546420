import * as React from 'react';
import Tabs, { tabsClasses } from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import MyTable from "./MyTable"
import MyTable2 from "./MyTable2"


export default function MyTableDashboard() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Tabs aria-label="tabs" defaultValue={0} sx={{ bgcolor: 'transparent' }}>
      <TabList
        disableUnderline
        sx={{
          p: 0.5,
          gap: 0.5,
          position: "sticky",
          top: 0,
          zIndex: 1000,
          display: "flex",
          alignItems: "center",
          padding: "8px",
          paddingLeft: "15px",
          borderRadius: "6px 6px 0px 0px",
          backgroundImage: "linear-gradient(to right, #6745ed, #DD39FD)",
          [`& .${tabClasses.root}[aria-selected="true"]`]: {
            boxShadow: 'sm',
            bgcolor: '#00000050',
            borderRadius: "8px"
          },
          [`& .${tabClasses.root}:hover`]: {
            bgcolor: '#00000025',
            borderRadius: "8px",
            boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1)', 
            color: 'text.primary', 
          },
        }}
      >
        <Tab disableIndicator style={{marginLeft: "8px"}}>VLP</Tab>
        <Tab disableIndicator>VLP2</Tab>
      </TabList>
      <TabPanel value={0}><MyTable /></TabPanel>
      <TabPanel value={1}><MyTable2 /></TabPanel>
    </Tabs>
  );
}
