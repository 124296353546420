import React, { useState } from 'react';
import Terminal, { ColorMode, TerminalInput, TerminalOutput } from 'react-terminal-ui';
import "./css/MyConsole.css";

const Ctb = ({ text }) => {
  return (
    <span style={{ lineHeight: "2", color: "#DD39FD", backgroundColor: "#0b0d0e", borderRadius: "3px", padding: "4px", fontSize: "0.8rem", fontWeight: 700, fontFamily: "Victor Mono" }}>
      {text}
    </span>
  );
}

const Ct = ({ text }) => {
  return (
    <span style={{ lineHeight: "2", color: "white", padding: "4px", fontSize: "0.8rem", fontWeight: 700, fontFamily: "Victor Mono" }}>
      {text}
    </span>
  );
}

const MyConsole = () => {
  const [lineData, setLineData] = useState([
    <TerminalOutput><span style={{lineHeight: "2.5", fontWeight: 800, fontFamily: "Victor Mono"}}><strong>Vítej ve VLP terminálu, zatím umím tyto příkazy:</strong></span></TerminalOutput>,
    <TerminalOutput><Ctb text="vlp resetCw" /><Ct text="Vynuluje všem CW body, zpřístupní nové odměny apod." /></TerminalOutput>,
    <TerminalOutput><Ctb text="vlp declineAll" /><Ct text="Odmítne všechny nedořešené přihlášky." /></TerminalOutput>,
    <TerminalOutput><Ctb text="vlp set cwendtime DD. MM. RRRR HH:MM" /><Ct text="Nastaví datum a čas konce CW, v tento datum vyhlásí 3 vítěze s největší počtem bodů." /></TerminalOutput>
  ]);

  const sendResetRequest = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://clan.varmi.cz/api/points/reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error('Failed to reset clan points');
      }

      const data = await response.json();
      return `Success: ${data.message}`;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const sendEndTimeUpdateRequest = async (dateTime) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://clan.varmi.cz/api/settings/update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          cwEndTime: dateTime
        })
      });

      if (!response.ok) {
        throw new Error('Failed to update settings');
      }

      const data = await response.json();
      return `Success: ${data.message}`;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const convertDateTimeToIso = (dateTimeStr) => {
    // Upravený regex, který akceptuje jednu nebo žádnou mezeru kolem teček
    const dateTimePattern = /^(\d{2})\.?\s?(\d{2})\.?\s?(\d{4})\s(\d{2}):(\d{2})$/;
    const match = dateTimePattern.exec(dateTimeStr);
    if (match) {
      const [, day, month, year, hour, minute] = match;
      return `${year}-${month}-${day}T${hour}:${minute}:00`;
    }
    return null;
  };
  

  const onInput = async (input) => {
    let ld = [...lineData, <TerminalInput>{input}</TerminalInput>];
    const inputTrimmed = input.trim();
    const commandParts = inputTrimmed.split(/\s+/);
  
    if (commandParts[0].toLowerCase() === 'vlp' && commandParts[1]?.toLowerCase() === 'resetcw') {
      try {
        const response = await sendResetRequest();
        ld.push(<TerminalOutput>{response}</TerminalOutput>);
        setLineData(ld);
      } catch (error) {
        ld.push(<TerminalOutput>{`Error: ${error.message}`}</TerminalOutput>);
        setLineData(ld);
      }
    } else if (commandParts[0].toLowerCase() === 'vlp' && commandParts[1]?.toLowerCase() === 'set' && commandParts[2]?.toLowerCase() === 'cwendtime') {
      if (commandParts.length > 3) {
        const dateTimeStr = commandParts.slice(3).join(' ');
        const dateTimeIso = convertDateTimeToIso(dateTimeStr);
        if (dateTimeIso) {
          sendEndTimeUpdateRequest(dateTimeIso)
            .then(response => {
              ld.push(<TerminalOutput>{response}</TerminalOutput>);
              setLineData(ld);
            })
            .catch(error => {
              ld.push(<TerminalOutput>{`Error: ${error}`}</TerminalOutput>);
              setLineData(ld);
            });
        } else {
          ld.push(<TerminalOutput>Špatný formát času, použij DD. MM. RRRR HH:MM</TerminalOutput>);
          setLineData(ld);
        }
      } else {
        ld.push(<TerminalOutput>Nesprávný příkaz!</TerminalOutput>);
        setLineData(ld);
      }
    } else {
      ld.push(<TerminalOutput>Neznámý příkaz</TerminalOutput>);
      setLineData(ld);
    }
};

  

  return (
    <div className="container" style={{width: "100%", maxHeight: "150px"}}>
      <Terminal name='VLP CONSOLE' colorMode={ColorMode.Dark} onInput={onInput}>
        {lineData}
      </Terminal>
    </div>
  );
};

export default MyConsole;